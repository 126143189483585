export enum Permissions {
  MANAGE_ORG_PROFILE = "organization_manage_organization_profile",
  MANAGE_ORG_ROLES = "organization_manage_roles",
  MANAGE_USERS = "users_manage_users",
  VIEW_USERS = "users_view_users",
  MANAGE_ORG_BILLING_ENTITIES = "organization_manage_billing_entity",
  VIEW_ONEDRIVE_STORAGE = "storage_onedrive_view",
  VIEW_STORAGE = "storage_view",
  CREATE_STORAGE = "storage_create",
  EDIT_STORAGE = "storage_edit",
  DELETE_STORAGE = "storage_delete",
  VIEW_CALENDAR = "calendar_view",
  CREATE_CALENDAR = "calendar_create",
  EDIT_CALENDAR = "calendar_edit",
  DELETE_CALENDER = "calendar_delete",
  VIEW_CLIENT_PROFILE = "client_profile_view",
  EDIT_CLIENT_PROFILE = "client_profile_edit",
  VIEW_CLIENT_GROUP_PROFILE = "client_group_profile_view",
  EDIT_CLIENT_GROUP_PROFILE = "client_group_profile_edit",
  CLIENT_PORTAL_ACCESS = "client_profile_client_portal_access",
  CREATE_CLIENT_PASSWORDS = "client_passwords_create",
  VIEW_CLIENT_PASSWORDS = "client_passwords_view",
  EDIT_CLIENT_PASSWORDS = "client_passwords_edit",
  DELETE_CLIENT_PASSWORDS = "client_passwords_delete",
  CREATE_CLIENT_GROUP_PASSWORDS = "client_group_passwords_create",
  VIEW_CLIENT_GROUP_PASSWORDS = "client_group_passwords_view",
  EDIT_CLIENT_GROUP_PASSWORDS = "client_group_passwords_edit",
  DELETE_CLIENT_GROUP_PASSWORDS = "client_group_passwords_delete",
  CREATE_CLIENT_KYB = "client_kyb_create",
  VIEW_CLIENT_KYB = "client_kyb_view",
  EDIT_CLIENT_KYB = "client_kyb_edit",
  DELETE_CLIENT_KYB = "client_kyb_delete",
  CREATE_CLIENT_GROUP_KYB = "client_group_kyb_create",
  VIEW_CLIENT_GROUP_KYB = "client_group_kyb_view",
  EDIT_CLIENT_GROUP_KYB = "client_group_kyb_edit",
  DELETE_CLIENT_GROUP_KYB = "client_group_kyb_delete",
  VIEW_CLIENT_RECURRING_PROFILE = "client_recurring_profile_view",
  EDIT_CLIENT_RECURRING_PROFILE = "client_recurring_profile_edit",
  TERMINATE_CLIENT_RECURRING_PROFILE = "client_recurring_profile_terminate",
  VIEW_CLIENT_GROUP_RECURRING_PROFILE = "client_group_recurring_profile_view",
  EDIT_CLIENT_GROUP_RECURRING_PROFILE = "client_group_recurring_profile_edit",
  TERMINATE_CLIENT_GROUP_RECURRING_PROFILE = "client_group_recurring_profile_terminate",
  VIEW_CLIENT_GROUPING = "client_grouping_view",
  CREATE_CLIENT_GROUPING = "client_grouping_create",
  EDIT_CLIENT_GROUPING = "client_grouping_edit",
  DELETE_CLIENT_GROUPING = "client_grouping_delete",
  VIEW_CLIENT_ATTACHMENTS = "client_attachments_view",
  CREATE_CLIENT_ATTACHMENTS = "client_attachments_create",
  EDIT_CLIENT_ATTACHMENTS = "client_attachments_edit",
  DELETE_CLIENT_ATTACHMENTS = "client_attachments_delete",
  VIEW_CLIENT_GROUP_ATTACHMENTS = "client_group_attachments_view",
  CREATE_CLIENT_GROUP_ATTACHMENTS = "client_group_attachments_create",
  EDIT_CLIENT_GROUP_ATTACHMENTS = "client_group_attachments_edit",
  DELETE_CLIENT_GROUP_ATTACHMENTS = "client_group_attachments_delete",
  VIEW_CLIENT_STORAGE = "client_storage_view",
  CREATE_CLIENT_STORAGE = "client_storage_create",
  EDIT_CLIENT_STORAGE = "client_storage_edit",
  DELETE_CLIENT_STORAGE = "client_storage_delete",
  VIEW_ALL_TASKS = "task_view_all",
  VIEW_ASSIGNED_TASKS = "task_view_assigned",
  CREATE_TASK = "task_create",
  EDIT_TASK = "task_edit",
  DELETE_TASK = "task_delete",
  TERMINATE_TASK = "task_terminate",
  VIEW_CATEGORIES = "categories_view",
  CREATE_CATEGORIES = "categories_create",
  EDIT_CATEGORIES = "categories_edit",
  DELETE_CATEGORIES = "categories_delete",
  VIEW_SERVICES = "services_view",
  CREATE_SERVICES = "services_create",
  EDIT_SERVICES = "services_edit",
  CLONE_SERVICES = "service_clone",
  DELETE_SERVICES = "services_delete",
  STATUS_SERVICES = "services_status",
  DELETE_LEADS = "leads_delete",
  EDIT_LEADS = "leads_edit",
  CREATE_LEADS = "leads_create",
  VIEW_LEADS = "leads_view",
  VIEW_DSC_REGISTER = "dsc_register_view",
  CREATE_DSC_REGISTER = "dsc_register_create",
  EDIT_DSC_REGISTER = "dsc_register_edit",
  DELETE_DSC_REGISTER = "dsc_register_delete",
  VIEW_CLIENTS = "clients_view",
  CREATE_CLIENTS = "clients_create",
  EDIT_CLIENTS = "clients_edit",
  DELETE_CLIENTS = "clients_delete",
  VIEW_CLIENT_GROUPS = "client_groups_view",
  CREATE_CLIENT_GROUPS = "client_groups_create",
  EDIT_CLIENT_GROUPS = "client_groups_edit",
  DELETE_CLIENT_GROUPS = "client_groups_delete",
  VIEW_REPORTS = "reports_view_reports",
  VIEW_ADMIN_DASHBOARD = "dashboard_view_admin_dashboard",
  VIEW_SERVICE_DASHBOARD = "view_service_dashboard",
  CREATE_INVOICE = "invoice_add",
  EDIT_INVOICE = "invoice_edit",
  CANCEL_INVOICE = "invoice_cancel",
  PREVIEW_INVOICE = "invoice_view",
  VIEW_INVOICE = "invoice_view",
  CREATE_PROFORMA_INVOICE = "invoice_proforma_add",
  EDIT_PROFORMA_INVOICE = "invoice_proforma_edit",
  CANCEL_PROFORMA_INVOICE = "invoice_proforma_cancel",
  PREVIEW_PROFORMA_INVOICE = "invoice_proforma_view",
  VIEW_PROFORMA_INVOICE = "invoice_proforma_view",
  CONVERT_PROFORMA_INVOICE = "invoice_proforma_convert",
  CLIENT_BILLING_VIEW = "invoice_view",
  DOWNLOAD_INVOICE = "invoice_download",
  INVOICE_PREVIEW_DOWNLOAD = "invoice_download",
  EXPORT_INVOICES_DOWNLOAD = "invoice_download",
  DOWNLOAD_PROFORMA_INVOICE = "invoice_proforma_download",
  PROFORMA_INVOICE_PREVIEW_DOWNLOAD = "invoice_proforma_download",
  EXPORT_PROFORMA_INVOICES_DOWNLOAD = "invoice_proforma_download",
  CLIENT_BILLING_OVERVIEW = "client_overview",
  RECEIPT_VIEW = "receipt_view",
  RECEIPT_PREVIEW = "receipt_view",
  ADD_RECEIPT = "receipt_add",
  INVOICE_RECEIPT_PAYMENT = "receipt_add",
  DOWNLOAD_RECEIPT = "receipt_download",
  EDIT_RECEIPT = "receipt_edit",
  RECEIPT_DELETE = "receipt_delete",
  RECEIPT_PREVIEW_DOWNLOAD = "receipt_download",
  MANAGE_NOTIFICATION_PREFERENCES = "manage_notification_preferences",
  MANAGE_USER_ATTENDANCE = "manage_user_attendance",
  MANAGE_ATTENDANCE_PREFERENCES = "manage_attendance_preferences",
  MANAGE_CLIENT_PREMISSION = "manage_client_permission",
  DELETE_UDIN = "udin_delete",
  EDIT_UDIN = "udin_edit",
  CREATE_UDIN = "udin_create",
  VIEW_UDIN = "udin_view",
  MANAGE_USER_TIMESHEET = "manage_user_timesheet",
  VIEW_ATOM_PRO = "view_atom_pro",
  VIEW_INCOME_TAX = "view_income_tax",
  CLIENT_ADD_INCOMETAX = "client_add_incometax",
  PASSWORD_VIEW_INCOMETAX = "password_view_incometax",
  PASSWORD_EDIT_INCOMETAX = "password_edit_incometax",
  SYNC_ACCESS = "sync_access",
  VIEW_GSTR = "view_gst",
  CLIENT_ADD_GST = "client_add_gst",
  PASSWORD_VIEW_GSTR = "password_view_gst",
  PASSWORD_EDIT_GSTR = "password_edit_gst",
  SYNC_ACCESS_GSTR = "sync_access_gst",
  BULK_IMPORT_ATOMPRO = "bulk_import_atompro",
  BROADCAST_CLIENT_GROUP_CREATE = "broadcast_client_group_create",
  BROADCAST_CLIENT_GROUP_EDIT = "broadcast_client_group_edit",
  BROADCAST_CLIENT_GROUP_DELETE = "broadcast_client_group_delete",
  BROADCAST_CLIENT_GROUP_VIEW = "broadcast_client_group_view",
  BROADCAST_EMAIL_TEMPLATES_CREATE = "broadcast_email_templates_create",
  BROADCAST_EMAIL_TEMPLATES_EDIT = "broadcast_email_templates_edit",
  BROADCAST_EMAIL_TEMPLATES_DELETE = "broadcast_email_templates_delete",
  BROADCAST_EMAIL_TEMPLATES_VIEW = "broadcast_email_templates_view",
  BROADCAST_BROADCAST_MESSAGE_CREATE = "broadcast_broadcast_message_create",
  BROADCAST_BROADCAST_MESSAGE_VIEW = "broadcast_broadcast_message_view",







}
